const bodyScan = {
  id: "body-scan-modal",
  modalTitle: "Body Scan",
  slides: [
    {
      type: "video",
      en: "d773556800fedfca3a908a4ee41973ed",
      fr: "be75d9f09d132691333f66ec5a6b79c6",
      es: "babb4609222d5cabc1aff2371a3a19f7",
      vi: "16f3b7dc5a9e1c6592953f1579e98c1e",
      ko: "7b9b2ff08741c88e7ff7398eb5730798",
      tr: "dc824cc66f7cc6b118a16e2e00905be3",
      ar: "5f8edace41e3757fe751c741575b540e",
    },
    {
      type: "discuss",
      en: "What did you notice when you did the body scan?",
      fr: "Qu'avez-vous remarqué pendant que votre corps était scanné?",
      es: "¿Qué notaste cuando hiciste el análisis corporal?",
      vi: "Bạn chú ý đến điều gì khi cơ thể bạn bị quét?",
      ko: "바디스캔할 때 기분이 어떠셨습니까?",
      tr: "Vücut taramasını yaparken ne farkettiniz?",
      ar: "ماذا شعرت عندما قمت بالمسح الواعى لجسدك ؟",
    },
    {
      type: "discuss",
      en: "Why do you think doing a body scan helped Katie?",
      fr: "Pourquoi pensez-vous que le fait de scanner son corps a été bénéfique pour Katie?",
      es: "¿Que has notado cuando escuchaste la campana?",
      vi: "Tại sao bạn nghĩ việc quét cơ thể đã giúp Katie?",
      ko: "바디스캔이 케이티에게 왜 도움이 되었다고 생각합니까?",
      tr: "Neden vücut taraması yapmanın Katie'ye yardım ettiğini düşünüyorsun?",
      ar: "ماذا لاحظت عندما سمعت الجرس ؟",
    },
    {
      type: "discuss",
      en: "How do you feel right before you take a test? Talk about how doing a body scan might help.",
      fr: "Comment vous sentiez-vous juste avant de passer un test ? Décrivez pourquoi le fait de scanner son corps peut être bénéfique.",
      es: "¿Cómo te sientes justo antes de presentar un examen? Habla sobre cómo podría ayudar el realizar un análisis corporal.",
      vi: "Bạn cảm thấy như thế nào ngay trước khi thực hiện bài kiểm tra? Chia sẻ về việc quét cơ thể giúp được mình như thế nào?",
      ko: "테스트 받기 직전에 기분이 어떠신가요? 바디스캔은 얼마나 길게 하는 것이 좋을지 이야기해 보세요.",
      tr: "Sınavdan önce kendinizi nasıl hissediyorsunuz? Vücut taraması yapmanın nasıl yardımcı olabileceğinden bahsedin.",
      ar: "ماذا تشعر  قبل أن تؤدي اختبار مباشرة ؟ تحدث عن كيف يمكن للمسح الواعى للجسد ان يساعدك ؟",
    },
  ],
};

const focusedFeet = {
  id: "focused-feet-modal",
  modalTitle: "Focused Feet",
  slides: [
    {
      type: "video",
      en: "1a66ccb9d363f89eabbc5cff44152823",
      fr: "5609ef97133fbdbd04aa9c06dfb30003",
      es: "5cacedacc961e7bbe7df398a704e3d8d",
      vi: "a9082e615c3672b21b4d7200d449160f",
      ko: "4ffaab7c5d7994b5f0f93dc98e0bedc5",
      tr: "914d0524a344beaef2a222b642b9d48e",
      ar: "1fdab07fd1a8a46eba77f6ffdb72decc",
    },
    {
      type: "discuss",
      en: "What sensations did you notice in your feet?",
      fr: "Quelles sensations avez-vous remarqué dans vos pieds?",
      vi: "Những cảm giác bạn nhận thấy ở bàn chân mình là gì?",
      ko: "발에서 어떤 감각을 느꼈습니까?",
      tr: "Ayaklarınızda hangi hisleri fark ettiniz?",
      es: "¿Qué sensaciones notaste en tus pies?",
      ar: "ما هي الاحاسيس التى لاحظتها فى قدميك ؟",
    },
    {
      type: "discuss",
      en: "Talk about how focusing on your feet makes you feel.",
      fr: "Décrivez ce que vous avez ressenti en vous concentrant sur vos pieds.",
      vi: "Sự tập trung vào bàn chân mình khiến bạn cảm thấy như thế nào?",
      ko: "발에 초점을 둘 때 기분이 어떤지 이야기해 보세요.",
      tr: "Ayaklarınıza odaklanmanın size nasıl hissettirdiğinden bahsedin.",
      es: "Habla sobre cómo te hace sentir concentrarte en tus pies.",
      ar: "تحدث عن مشاعرك عندما تقوم بالتركيز على قدميك .",
    },
    {
      type: "discuss",
      en: "How could focusing on your feet help you like it helped Bruce?",
      fr: "Comment le fait de vous concentrer sur vos pieds vous a aidé comme cela a aidé Bruce?",
      vi: "Việc tập trung vào bàn chân đã giúp bạn như thế nào như nó đã giúp Bruce?",
      ko: "발에 초점 두기가 부르스에게 도움이 되었는데, 당신에게는 어떻게 도움이 될 수 있을까요?",
      tr: "Ayaklarına odaklanmak, Bruce'a yardım ettiği gibi size nasıl yardımcı olabilir?",
      es: "¿Cómo podría ayudarte el concentrarte en tus pies como ayudó a Bruce?",
      ar: "كيف يمكن للتركيز على قدميك ان يساعدك كما حدث مع بروس ؟",
    },
  ],
};

const mindfulBreathing = {
  id: "mindful-breathing-modal",
  modalTitle: "Mindful Breathing",
  slides: [
    {
      en: "9ccc2e26fdaa11c15eebf989bcea69bb",
      es: "7d16257d6f93a87db8d591addc617caf",
      fr: "f45eb14b1694ed2889e6664b1dcb61a6",
      es: "c4e51129c359ccf3558c35263e9e9b70",
      vi: "af62a9eada23802ac9e9c4b39beceacf",
      ko: "89becdb3e511c2c5a1226f9067fe36ff",
      tr: "c4e51129c359ccf3558c35263e9e9b70",
      ar: "ae99339bf3befb83a678f52e2ebd4e73",
    },
    {
      type: "discuss",
      en: "How did it feel when you focused on your breath?",
      fr: "Qu'avez-vous ressenti en vous concentrant sur votre respiration?",
      es: "¿Cómo te sentiste cuando te concentraste en tu respiración?",
      vi: "Bạn cảm thấy thế nào khi bạn tập trung vào hơi thở?",
      ko: "호흡에 초점을 두면 기분이 어땠나요?",
      tr: "Nefesine odaklandığında nasıl hissettin?",
      ar: "كيف شعرت عندما قمت بالتركيز على نفسك ؟",
    },
    {
      type: "discuss",
      en: "Talk about a time that you were in a situation like Mojo. What did you do?",
      fr: "Décrivez un moment où vous étiez dans la même situation que Mojo. Qu’avez-vous fait?",
      es: "Habla sobre alguna ocasión en la que te encontraste en una situación similar a Mojo. ¿Qué hiciste?",
      vi: "Nói về thời gian mà bạn ở trong hoàn cảnh giống như Mojo. Bạn đã làm gì?",
      ko: "모조(Mojo)와 같은 상황을 경험했던 기억을 떠올려 봅시다. 무엇을 했습니까?",
      tr: "Mojo gibi bir durumda olduğun bir zaman hakkında bahset. Ne yaptın?",
      ar: "تحدث عن وقت كنت فيه فى موقف مشابه لموجو . ماذا فعلت ؟",
    },
    {
      type: "discuss",
      en: "Do you feel different now than you did before the lesson?",
      fr: "Vous sentez-vous différent maintenant de ce que vous l’étiez avant la leçon?",
      es: "¿Te sientes diferente ahora que antes de la lección?",
      vi: "Bây giờ bạn có cảm thấy khác hơn so với trước khi học bài học này?",
      ko: "수업하기 전과 비교할 때 기분이 달라졌나요?",
      tr: "Şimdi kendini dersten öncekinden farklı hissediyor musun?",
      ar: "هل تشعر باختلاف الآن عن ما كنت قبل الدرس ؟",
    },
  ],
};

const mindfulCountdown = {
  id: "mindful-countdown-modal",
  modalTitle: "Mindful Countdown",
  slides: [
    {
      type: "video",
      en: "452e7b8dfcebd6b9b631e363463864a5",
      fr: "3e66139bbffd4fd4db4b21e188e4783c",
      vi: "09a47033c77bf776d2a85434c27d2fa4",
      ko: "a00a0c4c331d01da2bf8615df4ce7ca6",
      tr: "cda7e9b15fa5652b9aeae289229740a7",
    },
    {
      type: "discuss",
      en: "Was the mindful countdown easy or hard for you? Why do you think so?",
      vi: "Đếm ngược tĩnh lặng dễ dàng hay khó khăn với bạn? Tại sao bạn nghĩ như vậy?",
      ko: "마음챙김 카운트 다운이 쉬우셨나요? 아니면 어려우셨나요? 왜 그렇게 생각하십니까?",
      fr: "La pleine conscience du compte à rebours a-t-elle été facile ou difficile pour vous? Pourquoi pensez-vous cela?",
      tr: "Bilinçli geri sayım sizin için kolay mıydı yoksa zor mu? Neden böyle düşünüyorsun?",
    },
    {
      type: "discuss",
      en: "Share about a time you’ve felt nervous speaking in front of class, like Mojo.",
      vi: "Hãy chia sẻ về lúc bạn cảm thấy bồn chồn lo lắng khi nói trước lớp như Mojo.",
      ko: "모조와 같이 클래스 앞에서 긴장하면서 말하던 시간에 대해서 나눠주세요.",
      fr: "Décrivez une fois où le fait de parler devant une classe a été stressant pour vous, comme pour Mojo.",
      tr: "Mojo gibi sınıfın önünde gergin hissettiğiniz bir zamanı paylaşın.",
    },
    {
      type: "discuss",
      en: "Why do you think mindful countdown helped Mojo? How might it help you?",
      vi: "Tại sao bạn nghĩ đếm ngược tĩnh lặng đã giúp Mojo? Và giúp bạn như thế nào?",
      ko: "마음챙김 카운트 다운이 왜 모조에게 도움이 되었다고 생각하십니까? 마음챙김 카운트 다운은 당신에게 어떤 도움이 될 것 같습니까?",
      fr: "Pourquoi pensez-vous que la pleine conscience du compte à rebours a été bénéfique pour Mojo ? Comment cela pourrait vous aider?",
      tr: "Bilinçli geri sayımın Mojo'ya neden yardımcı olduğunu düşünüyorsun? Size nasıl yardımcı olabilir?",
    },
  ],
};

const mindfulListening = {
  id: "mindful-listening-modal",
  modalTitle: "Mindful Listening",
  slides: [
    {
      type: "video",
      en: "c0df160ac3e178b02294b24205ad3755",
      fr: "5da94713f86aeb131bd1331b89272705",
      es: "6ba6d99f388c9100a089f4a2cd4a4dd0",
      vi: "a6204d77ea4d04d926bfb71c84c22624",
      ko: "7b9b2ff08741c88e7ff7398eb5730798",
      tr: "dc824cc66f7cc6b118a16e2e00905be3",
    },
    {
      type: "discuss",
      en: "What sounds did you hear? Did you find yourself getting distracted?",
      fr: "Quels sons avez-vous entendus ? Vous êtes-vous laissé distraire?",
      vi: "Những âm thanh nào bạn đã nghe? Bạn có bị xao nhãng không?",
      ko: "무슨 소리를 들었습니까? 주의력을 빼앗기는 것 같습니까?",
      tr: "Hangi sesleri duydunuz? Dikkatiniz hiç dağıldı mı?",
      es: "¿Qué sonidos escuchaste? ¿Te distrajiste?",
    },
    {
      type: "discuss",
      en: "Where does your mind like to wander?",
      fr: "Où votre esprit aime-t-il s’évader?",
      vi: "Tâm trí bạn có xu hướng lang thang đến nơi nào?",
      ko: "마음이 어디에서 방황하고 싶어하나요?",
      tr: "Aklın nerelerde dolaşmayı seviyor?",
      es: "¿Hacia dónde le gusta vagar a tu mente?",
    },
    {
      type: "discuss",
      en: "When do you think mindful listening might help you in school?",
      fr: "Où votre esprit aime-t-il s’évader?",
      vi: "Khi nào thì bạn nghĩ việc lắng nghe tĩnh lặng có thể giúp bạn ở trường? Và ở nhà thì sao?",
      ko: "Quand pensez-vous que l’écoute active pourrait vous aider à l'école ? Et à la maison?",
      tr: "Bilinçli dinlemenin okulda sana ne zaman yardım edebileceğini düşünüyorsun? Peki ya evde?",
      es: "¿Cuándo piensas que la escucha consciente podría ayudarte en la escuela? ¿Y en casa?",
    },
  ],
};

const mindfulMoment = {
  id: "mindful-moment-modal",
  modalTitle: "Mindful Moment",
  slides: [
    {
      type: "video",
      en: "5ec4f8c7834e5599de8c3ec90c52c88d",
      fr: "0d525793e192812dfb7a158feb3cd13f",
      es: "9409e19653be8cd14f7bc1219d0a6a73",
      vi: "701ce4c669f2008112757853d2dd6822",
      ko: "1d1ec99240515a1a41506ee46ac1386d",
      tr: "f13070db82d90a8a2cf9ba7dc7481582",
      ar: "c809f0ec2e88348bf5624f33db30c0f7",
    },
    {
      type: "discuss",
      en: "What did you notice when you heard the bell?",
      fr: "Qu’as-tu remarqué lorsque tu as entendu la cloche?",
      es: "¿Que has notado cuando escuchaste la campana?",
      vi: "Bạn đã chú ý điều gì khi bạn nghe tiếng chuông?",
      ko: "벨소리를 듣고 무엇을 알아차렸어요?",
      tr: "Zili duyduğunuzda ne farkettiniz?",
      ar: "ماذا لاحظت عندما سمعت الجرس ؟",
    },
    {
      type: "discuss",
      en: "Talk about a time strong emotions took you on a wild ride.",
      fr: "Parle d’un moment où de fortes émotions t’ont fait basculer.",
      es: "Habla de una situación en la que tus emociones se han apoderado de ti.",
      vi: "Nói về một thời gian mà cảm xúc của bạn mạnh mẽ nhất trong suốt chuyến hành trình của mình.",
      ko: "강한 감정이 인생을 흔들렸을때 말해주세요.",
      tr: "Duyguların sizi vahşi bir yolculuğa çıkardığı an hayal edin.",
      ar: "تحدث عن احد الاوقات التى اجتاحتك فيها مشاعر قوية و جارفة.",
    },
    {
      type: "discuss",
      en: "When might focusing on your breathing help you in school? How about at home? Anywhere else? ",
      fr: "À l’école, à quel moment cela pourrait-il t’aider de te concentrer sur ta respiration? Et à la maison? N’importe où ailleurs?",
      es: "¿Cuando podria ayudarte enfocarte en tu respiración en la escuela? ¿Y en casa? ¿En otro lugar?",
      vi: "Thời điểm nào ở trường mà việc tập trung vào hơi thở giúp ích cho bạn? Ở nhà thì sao? Ở bất kì nơi nào khác thì thế nào?",
      ko: "호흡에 중심을 하면 학교에서 언제 도움이 될까요? 집에서는요? 다른 곳에서도 도움이 될까요?",
      tr: "Solunumunuza odaklanmak okulda size ne zaman yardımcı olabilir? Peki ya evde? Başka herhangi bir yerde?",
      ar: "متى يمكن للتركيز على التنفس ان يساعدك فى المدرسة ؟ و ماذا عن المنزل ؟ اى اماكن اخرى ؟",
    },
  ],
};

export { mindfulMoment, mindfulListening, mindfulBreathing, mindfulCountdown, focusedFeet, bodyScan };
