import React from "react";

import SEO from "@src/components/SEO";
import HeaderSubMenu from "@src/components/partials/mindfulmoment/HeaderSubMenu";
import CardsComponent from "@src/components/partials/mindfulmoment/CardsComponent";
import BenefitsComponent from "@src/components/partials/mindfulmoment/BenefitsComponent";

const MindfulmomentPage = () => {
  return (
    <>
      <SEO />
      <HeaderSubMenu />
      <CardsComponent />
      <BenefitsComponent />
    </>
  );
};
export default MindfulmomentPage;
